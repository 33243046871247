@font-face {
  font-family: 'Sqwared Medium';
  src: url('./assets/fonts/FSP DEMO - Sqwared Medium.otf') format('opentype'),
       url('./assets/fonts/FSP DEMO - Sqwared Medium.woff2') format('woff2'),
       url('./assets/fonts/FSP DEMO - Sqwared Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirNextLTPro-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirNextLTPro-It.otf') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirNextLTPro-Regular.otf') format('woff');
  font-weight: normal;
  font-style: normal;
}

.fade-in-up {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

.fade-in-up.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.jobscroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.jobscroll::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.jobscroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

.navbar{
  max-width: 1440px;
  width: 95%;  
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar > a{
margin-left: 5%;
}

.menu{
  color:#989898;
  display: flex;
  gap:40px;
  width: 600px;
  padding: 0px 5px 0px 30px;
  border-radius: 100px;
  align-items: center;
  list-style: none;
  font-family: Avenir;
  font-size: 13px;
  background-color: #121720;
  border: 1px solid transparent;
}

.menu1{
  color:#989898;
  display: flex;
  gap:40px;
  height: 36px;
  width: 600px;
  padding: 0px 5px 0px 30px;
  border-radius: 100px;
  align-items: center;
  list-style: none;
  font-family: Avenir;
  font-size: 13px;
  /* background-color: #121720; */
  border: 2px solid #989898;
}

.loginNav{
  display: flex;
  gap:10px;
  align-items: center;
}

.contact_button{
  background-color: transparent;
  padding: 7px 36px;
  border-radius: 29px;
  color:#2C2F3D;
  height: fit-content;
  font-family: Avenir;
  border: 0.8px solid #2C2F3D;
  backdrop-filter: blur(10px);
}

.login_button{
  background: #222533;
  padding: 7px 36px;
  border-radius: 29px;
  height: fit-content;
  font-family: Avenir;
  color:#ffff;
  border: 0.8px solid #2C2F3D;
  backdrop-filter: blur(10px);
}

.menu-container{
  display: flex;
  justify-content: space-around;
  gap:10%;
  padding-right: 5%;
}

@media screen and (max-width: 1024px) {
  .menu-container {
   display: none;
  }
  .navbar{
    width: 100%;
    position: relative;
    z-index: 100;
  }

  .menubutton{
    position: relative;
    z-index: 999;
    display:flex;
    background-color: white;
    width: 95px;
    font-family: Avenir;
    font-size: 14px;
    font-style: normal;
    margin-right: 5%;
    font-weight: 400;
    height: 35px;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 100px;
  }
}
@media screen and (min-width: 1024px) {
.menubutton{
  display: none;
}
}


li {
width:max-content;
}

li:first-child{
  color: #EF3733;
}


.navcontainer{
    position: fixed;
    width: 100%;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
}

.fade-in-out {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-in-out-hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}



.circle-menu{
  background-color: black;
  padding: 10px;
  border-radius: 999px;
}

.glass{
  height: auto;
  width: 100%;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba( 255, 255, 255, 0.8 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.0 );
  backdrop-filter: blur( 3px );
  -webkit-backdrop-filter: blur( 3px );
  border-radius: 10px;
  animation: glass 1s forwards ;
}

.glass-reverse{
  height: auto;
  width: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(21px);
  border: 1px solid #FFFFFF12;
  animation: glass-reverse 1s forwards ;
}

.mobilemenulist{
  margin-top: 550px;  
  margin-bottom: 50px;  
  align-items: center;
  list-style: none;
  font-family: Avenir;
  display: flex;
  font-size: 22px;
  text-align: center;
  padding-inline-start: 0px !important;
  color: white;
  flex-direction: column;
  gap:30px;
}

@keyframes glass{
  0%{
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0);
  }
}

@keyframes glass-reverse{
  0%{
    transform: translateY(0);
  }
  100%{
    transform: translateY(-110%);
  }
}

.leave{
  padding: 0px 20px;
  width: max-content;
  background: rgba( 255, 255, 255, 0.25 );
  color: white;
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}